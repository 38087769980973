// import './home.scss';
// console.log('home.js');

setTimeout(() => {
    jQuery(".ff-widget-carousel .swiper").each(function () {
        var swiperInstance = jQuery(this).data("swiper");

        if (swiperInstance) {
            swiperInstance.params.navigation.nextEl = jQuery(this).closest(".ff-widget-carousel").find(".button_arrows .next")[0];
            swiperInstance.params.navigation.prevEl = jQuery(this).closest(".ff-widget-carousel").find(".button_arrows .prev")[0];

            swiperInstance.destroy(false); 
            new Swiper(this, swiperInstance.params); 
        } else {
            console.error("Swiper instance is not defined for:", this);
        }
    });
}, 1000);